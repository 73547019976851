<template>
  <div v-if="showPreviewPDF" id="PreviewPdf" :style="`${StylePreviewPDF()}`">
    <div v-if="loadingStatus == LoadingStatusType.Loading" class="loading">
      <i class="fas fa-circle-notch fa-spin"></i>Loading... PDF content
    </div>
    <div v-if="loadingStatus == LoadingStatusType.Success" class="boxTool">
      <div :class="['pager', { singlePage: pdfNumberPage == 1 }]">
        <i v-if="currentPage !== 1" class="fas fa-angle-left" @click="PreviousPage()"></i>
        <p>{{ currentPage }}/{{ pdfNumberPage }}</p>
        <i v-if="currentPage !== pdfNumberPage" class="fas fa-angle-right" @click="NextPage()"></i>
      </div>
      <div class="d-flex">
        <div class="RotateBack">
          <i class="fas fa-undo ml-2" @click="RotateBack()"></i>
        </div>
        <div class="RotateNext">
          <i class="fas fa-redo ml-2" @click="RotateNext()"></i>
        </div>
        <div class="zoomIn">
          <i class="fas fa-plus ml-2" @click="ZoomIn()"></i>
        </div>
        <div class="zoomOut">
          <i class="fas fa-minus ml-2" @click="ZoomOut()"></i>
        </div>
      </div>
    </div>
    <div :class="['pdfBox', { show: loadingStatus == LoadingStatusType.Success }]">
      <PDF :src="pdfLoading" :page="currentPage" @loaded="loaded" :rotate="rotate" :style="`width: ${zoom}%`"></PDF>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { LoadingStatusType } from "@/enums/enums";
export default {
  props: {
    fileUrl: {
      type: String,
      default: "",
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PDF: () => import("vue-pdf"),
  },

  data() {
    return {
      pdfLoading: null,
      pdfNumberPage: 0,
      currentPage: 1,
      loadingStatus: LoadingStatusType.None,
      showPreviewPDF: false,
      rotate: 0,
      zoom: 100,
      LoadingStatusType: LoadingStatusType,
    };
  },
  watch: {
    showPreview: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.showPreview) {
          this.showPreviewPDF = true;
        }
      },
    },
    showPreviewPDF: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.showPreviewPDF) {
          this.$emit("pdf-loading");
          this.loadingStatus = LoadingStatusType.Loading;
          this.pdfLoading = pdf.createLoadingTask(this.fileUrl);

          this.$nextTick(() => {
            this.pdfLoading.promise.then((pdf) => {
              this.pdfNumberPage = pdf.numPages;
            });
          });
        }
      },
    },
  },

  methods: {
    StylePreviewPDF() {
      let rotate = (this.rotate * -1) / 90;
      rotate = rotate % 2;
      const style = `align-items:${rotate == 0 ? "unset" : "center"};display:${rotate == 0 ? "block" : "flex"};`;
      return style;
    },
    RotateBack() {
      this.rotate -= 90;
    },
    RotateNext() {
      this.rotate += 90;
    },
    ZoomIn() {
      this.zoom = this.zoom + 20;
    },
    ZoomOut() {
      if (this.zoom > 100) {
        this.zoom = this.zoom - 20;
      }
    },
    loaded() {
      this.$nextTick(() => {
        this.loadingStatus = LoadingStatusType.Success;
        this.$emit("pdf-loaded");
      });
    },
    NextPage() {
      if (this.currentPage < this.pdfNumberPage) {
        this.currentPage = this.currentPage + 1;
      }
    },
    PreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
      }
    },
  },

  beforeDestroy() {
    this.pdfLoading = null;
  },
};
</script>

<style lang="scss" scoped>
#PreviewPdf {
  height: 100%;
  .loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    i {
      margin-right: 10px;
    }
  }
  .pdfBox {
    position: absolute;
    opacity: 0;
  }
  .pdfBox.show {
    position: unset;
    opacity: 1;
  }
  .boxTool {
    opacity: 0;
    position: absolute;
    top: 20px;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    align-items: center;
    .pager {
      background: #282727;
      padding: 0px 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      p,
      i {
        color: white;
      }
      p {
        margin: 0px 10px 0px 10px;
      }
      i {
        cursor: pointer;
      }
    }
    .singlePage {
      visibility: hidden;
    }
    .RotateBack,
    .RotateNext,
    .zoomIn,
    .zoomOut {
      cursor: pointer;
      i {
        background: #282727;
        padding: 8px;
        font-size: 12px;
        color: white;
        border-radius: 50%;
        transition: 0.5s;
      }
    }
  }

  &:hover {
    .boxTool {
      opacity: 1;
    }
  }
}
</style>
